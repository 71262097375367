import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import "../styles/button.scss"

const Button = ({ children, disabled, size, href, linkTo, download }) => {
  if (href)
    return (
      <a
        className={`button meiquer-button ${size}`}
        target="_blank"
        rel="noopener noreferrer"
        href={href}
        download={download}
      >
        {children}
      </a>
    )
  else if (linkTo)
    return (
      <Link className={`button meiquer-button ${size}`} to={linkTo}>
        {children}
      </Link>
    )
  else
    return (
      <button className={`button meiquer-button ${size}`} disabled={disabled}>
        {children}
      </button>
    )
}

Button.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(["small", "normal", "medium", "large"]),
  href: PropTypes.string,
  linkTo: PropTypes.string,
  download: PropTypes.bool,
}

Button.defaultProps = {
  children: "Enviar",
  disabled: false,
  download: false,
  size: "normal",
}

export default Button
